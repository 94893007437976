import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/.pnpm/next-intl@3.26.5_next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macro_povg5kxv43tl7xftzlgy6tdtgm/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"Noto_Sans\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"style\":[\"normal\"],\"display\":\"swap\",\"variable\":\"--font-notosans\"}],\"variableName\":\"notosans\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"Noto_Serif\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"display\":\"swap\",\"variable\":\"--font-notoserif\"}],\"variableName\":\"notoserif\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"preload\":true,\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\"],\"display\":\"swap\",\"variable\":\"--font-playfair\"}],\"variableName\":\"playfair\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"../../../public/fonts/sf/sf-pro-display-light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/sf/sf-pro-display-medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/sf/sf-pro-display-bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/sf/sf-pro-display-heavy.woff2\",\"weight\":\"800\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-sf\"}],\"variableName\":\"sf\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"Libre_Bodoni\",\"arguments\":[{\"preload\":false,\"subsets\":[\"latin\"],\"weight\":[\"700\"],\"style\":[\"italic\",\"normal\"],\"display\":\"swap\",\"variable\":\"--font-libre\"}],\"variableName\":\"libre\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"\",\"arguments\":[{\"preload\":false,\"src\":[{\"path\":\"../../../public/fonts/trt/TRT-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/trt/TRT-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/trt/TRT-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-trt\"}],\"variableName\":\"trt\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.en.ts\",\"import\":\"Arima\",\"arguments\":[{\"preload\":false,\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-arima\",\"weight\":[\"500\",\"600\",\"700\"]}],\"variableName\":\"arima\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/public/image/ads-background.jpeg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/(routes)/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/cards/home/MediaCard.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/cards/home/VideoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/usr/src/app/src/app/components/carousel/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/channel-logo/channelStyle.module.scss");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/font.ur.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"../../../public/fonts/jameel-noori-nastaleeq/Jameel-Noori-Nastaleeq.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/jameel-noori-nastaleeq/Jameel-Noori-Nastaleeq-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-sf\"}],\"variableName\":\"ukijzilwa\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts/font.uig.ts\",\"import\":\"\",\"arguments\":[{\"preload\":true,\"src\":[{\"path\":\"../../../public/fonts/ukijzilwa/ALKATIP-regular.ttf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/ukijzilwa/ALKATIP-Tor-Tom-bold.ttf\",\"weight\":\"700\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-sf\"}],\"variableName\":\"ALKATIP\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.ps.ts\",\"import\":\"Vazirmatn\",\"arguments\":[{\"preload\":true,\"subsets\":[\"arabic\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-vazirmatn\"}],\"variableName\":\"vazirmatn\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.fa.ts\",\"import\":\"Vazirmatn\",\"arguments\":[{\"preload\":true,\"subsets\":[\"arabic\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-vazirmatn\"}],\"variableName\":\"vazirmatn\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.fa-AF.ts\",\"import\":\"Vazirmatn\",\"arguments\":[{\"preload\":true,\"subsets\":[\"arabic\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-vazirmatn\"}],\"variableName\":\"vazirmatn\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts/font.ar.ts\",\"import\":\"Almarai\",\"arguments\":[{\"preload\":true,\"subsets\":[\"arabic\"],\"weight\":[\"300\",\"400\",\"700\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-almarai\"}],\"variableName\":\"almarai\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/common/AutoPlayByScroll.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/common/mini-player/MiniVideoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/common/VideoModal.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/link/ArticleLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AudioLink"] */ "/usr/src/app/src/app/components/link/AudioLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentLink"] */ "/usr/src/app/src/app/components/link/ContentLink.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/link/LinkWithChannel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoLink"] */ "/usr/src/app/src/app/components/link/VideoLink.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/media/LiveTvVideoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/media/VideoPlayer.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/notification/BreakingNewsContent.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/notification/BreakingNewsDots.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/notification/BreakingNewsWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/pages/home/LiveTvWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleUppercase"] */ "/usr/src/app/src/app/components/text/LocaleUppercase.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChannelProvider"] */ "/usr/src/app/src/providers/ChannelProvider.tsx");
